body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 80px 0 80px;
  width: 100%;
  height: 80px;
  background-color: white;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
}
.jumbotron {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  background-color: #0e0a2e;
  background-image: url('./images/jumboimg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.jumbotext {
  color: #0e0a2e;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 6.5rem;
}
.jumbotron img {
  width: 100%;
  height: 75vh;
  object-fit: cover;
}
.buttons {
  text-align: center;
  color: red;
}
.button {
  width: 300px;
  padding: 25px;
  background-color: #4fc2c0;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 10px 0 10px;
  font-size: 0.8rem;
}
.button:hover {
  background-color: white;
  color: #0e0a2e;
}
.howitworks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25vh;
  background-color: #0e0a2e;
}
.howtitle {
  color: white;
  font-weight: 500;
  font-size: 1rem;
}
.howcards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 15px;
  width: 100%;
  text-align: center;
}
.howcard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
}
.number {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 50%;
  font-size: 15px;
  padding: 10px;
}
.howsub {
  color: #ff8500;
}
.howdetails {
  color: #fff;
}
.services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 20px 80px;
}
.servicestitle {
  text-align: center;
  margin: 20px 0 20px 0;
  color: #4fc2c0;
  font-size: 6rem;
}
.cards {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  text-align: center;
}
.card {
  display: flex;
  flex-direction: column;
  border: 2px #f2f2f2 solid;
  width: 300px;
  padding: 10px;
  margin: 0 5px;
}
.cardtitle {
  text-align: center;
  margin-bottom: 10px;
  color: #0e0a2e;
}
.cardimg img {
  width: 100%;
  height: 200px;
  background-size: cover;
  border: 2px solid #0e0a2e;
  margin-bottom: 10px;
}
.time {
  text-align: center;
  background-color: #dedede;
  padding: 15px;
  margin-bottom: 10px;
  color: #0e0a2e;
}
.list {
  display: flex;
  flex-direction:column;
  list-style-type: none;
  margin-bottom: 10px;
}
.list li {
  text-align: center;
  border-bottom: 1px dotted gray;
  padding: 10px;
  color: #0e0a2e;
}
.price {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 5px;
  color: #4fc2c0;

}
.prices {
  color: #0e0a2e;
}
.services-ftr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px;
  padding: 20px 80px;
  background-color: #4fc2c0;
}
.ready {
  display: flex;
  align-items: center;
  color: #0e0a2e;
}
.ready-btn {
  padding: 25px;
  background-color: #fff;
  color: #0e0a2e;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  margin-left: 10px;
}
.ready-btn:hover {
  padding: 25px;
  background-color: #0e0a2e;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  margin-left: 10px;
}
.request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 80px;
}
.request-title {
  color: #0e0a2e;
  text-align: center;
}
.request-sub {
  margin-bottom: 20px;
  color: #0e0a2e;
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  background-color: #0e0a2e;
  color: #fff;
  margin: 0 auto;
}
.fieldset {
  border: none;
  padding: 0 30px;
  margin: 20px 0;
  text-align: left;
  width: 100%;
}
.fieldset input {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.checkspace {
  margin-right: 5px;
}
.address-fields input {
  width: 23%;
}
.fieldset textarea {
  width: 50%;
  height: 5rem;
}
.makemodel {
  color: #ff8500;
}
.submit-btn {
  padding: 15px;
  background-color: #fff;
  color: #0e0a2e;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 0px 0px 30px 30px;
}
.submit-btn:hover {
  background-color: #4fc2c0;
  color: white;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 200px;
  padding: 20px 80px 0px 80px;
  background-color: #000523;
  border-top: 5px solid #dedede;
}
.ftr-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}
.ftr-top-left {
  display: flex;
  align-items: center;
  width: 50%;
  height: auto;
}
.ftr-top-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  height: auto;
}
.ftr-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
}
.ftr-btm-left {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: auto;
}
.ftr-btm-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  height: auto;
}
.btm-contact {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
}
.btm-partition {
  color: #ff7800;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  padding: 0 15px;
}
.social {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  font-weight: 900;
  border: 2px solid #ff7800;
  border-radius: 50%;
}
.ig {
  margin-left: 5px;
}
.privacy-policy {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
}
.ftr-addy {
  margin-left: 15px;
}

/* MEDIA QUERY */

@media screen and (max-width: 768px) {
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
    text-align: center;
  }
  .jumbotron {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75vh;

    background-image: url('./images/jumboimg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  }
  .jumbotext {
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 3.5rem;
    padding: 10px;
  }
  .jumbotron img {
    width: 100%;
    height: 75vh;
    object-fit: cover;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .button {
    width: 190px;
    padding: 25px;
    background-color: #4fc2c0;
    color: white;
    border: none;
    cursor: pointer;
    margin: 0 0px 10px 0px;
    font-size: 0.8rem;
  }
  .button:hover {
    background-color: white;
    color: #0e0a2e;
  }
  .howitworks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25vh;
    background-color: #0e0a2e;
    padding: 10px;
  }
  .howcards {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 10px;
    width: 100%;
    text-align: center;
  }
  .howcard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .number {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 50%;
    font-size: 15px;
    padding: 10px;
  }
  .howsub {
    color: #ff8500;
    font-size: 0.7rem;
  }
  .howdetails {
    color: #fff;
    font-size: 0.6rem;
  }

  /*  SERVICES  */
  .services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 20px 80px;
  }
  .servicestitle {
    text-align: center;
    margin: 20px 0 20px 0;
    color: #4fc2c0;
    font-size: 1.8rem;
  }
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 2px #f2f2f2 solid;
    width: 250px;
    padding: 10px;
    margin: 0 5px 10px 5px;
  }
  .services-ftr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    margin: 20px;
    padding: 20px 40px;
    background-color: #4fc2c0;
  }
  .prices {
    font-size: 0.9rem;
    margin-bottom: 10px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 2px #fff dotted;
  }
  .ready {
    display: flex;
    align-items: center;
    color: #0e0a2e;
    font-size: 0.7rem;
    line-height: 1;
    text-align: center;
  }
  .ready-btn {
    padding: 25px;
    background-color: #fff;
    color: #0e0a2e;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 10px;
  }
  .ready-btn:hover {
    padding: 25px;
    background-color: #0e0a2e;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 10px;
  }

  /* Request */

  .request {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 5px;
  }
  .request-title {
    color: #0e0a2e;
    text-align: center;
    font-size: 2rem;
  }
  .request-sub {
    margin-bottom: 20px;
    color: #ff8500;
    text-align: center;
    font-size: 1.5rem;
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #0e0a2e;
    color: #fff;
  }
  .fieldset {
    border: none;
    padding: 0 5px;
    margin: 15px 0;
    text-align: center;
    width: 100%;
  }
  .fieldset input {
    padding: 5px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  .address-fields input {
    width: 100%;
  }
  .fieldset textarea {
    width: 100%;
    height: 5rem;
  }
  .checkspace {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .makemodel {
    color: #ff8500;
  }
  .submit-btn {
    padding: 15px;
    background-color: #fff;
    color: #0e0a2e;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    margin: 0px 0px 30px 0px;
  }
  .submit-btn:hover {
    background-color: #4fc2c0;
    color: white;
  }
}

